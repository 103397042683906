import "./SignUpComponentV2.css";
import { useConfig } from "../../../../api/config";
import { WizzardComponent } from "../WizzardComponent/PreviousWizzard";
import new_logo from "../../../../assets/img/logo.png";
const SignUpComponentV2 = () => {
  const config = useConfig();
  return (
    <div className={"signup_component_v2"}>
      <header>
        <img
          src={
            config.BRAND_IMAGE_LINK === "activechat"
              ? new_logo
              : config.BRAND_IMAGE_BIG_LINK || config.BRAND_IMAGE_LINK
          }
          alt=""
          onClick={() => {
            window.location = "/";
          }}
        />
      </header>
      <WizzardComponent />
    </div>
  );
};

export default SignUpComponentV2;
