import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import ChatLandingComponent from "../ChatLandingComponent/ChatLandingComponent";
import LoginComponent from "../LoginComponent/LoginComponent";
import AppComponent from "../mainAppComponent/AppComponent/AppComponent";
import PricingRangeComponent from "../mainAppComponent/BillingComponent/PricingRangeComponent/PricingRangeComponent";
import SignUpComponentV2 from "../mainAppComponent/SignUpComponentV2/SignUpComponentV2";
import RedirectComponent from "../mainAppComponent/RedirectComponent/Redirect";
import WizzardComponent from "../mainAppComponent/WizzardComponent/WizzardComponent";
import MainCanvasConteiner from "../mainCanvasComponent/MainCanvasConteiner";
import NotfoundComponent from "../NotfoundComponent/NotfoundComponent";
import ResetComponent from "../ResetComponent/ResetComponent";
import ResetPswdPage from "../ResetPswdPage/ResetPswdPage";
import SvgLibrary from "../SvgLibrary/SvgLibrary";
import UiKit from "../UiKit/UiKit";
import { useConfig } from "../../../api/config";

const Root = () => {
  const config = useConfig();
  return (
    <Router basename="/v2">
      <Switch>
        <Route
          path="/lp/woocommerce"
          render={() => (
            <ChatLandingComponent agentID="09e2049d-5e66-4e6a-b26e-cd73f2aa5bff" />
          )}
        />

        <Route
          path="/lp/shopify"
          render={() => (
            <ChatLandingComponent agentID="966fd1f3-8280-457b-9f34-240cf2b66ff8" />
          )}
        />
        <Route path="/canvas" component={MainCanvasConteiner} />
        <Route path="/testPage" component={WizzardComponent} />
        <Route path="/svgLib" component={SvgLibrary} />
        <Route path="/uikit" component={UiKit} />
        <Route
          path="/pricingRange/:widgetShow"
          component={PricingRangeComponent}
        />
        <Route exact path="/login" component={LoginComponent} />
        <Route path="/login/rp/:code/:id" component={ResetPswdPage} />
        <Route exact path="/reset" component={ResetComponent} />
        {/* <Route exact path="/signup" component={RedirectComponent} /> */}
        {config.ALLOW_REGISTER === "true" && (
          <Route exact path="/signup" component={SignUpComponentV2} />
        )}

        <Route path="/" component={AppComponent} />
        <Route component={NotfoundComponent} />
      </Switch>
    </Router>
  );
};

export default Root;
