import React, { useState } from "react";
import { Redirect } from "react-router";
import { Link } from "react-router-dom";
import { useApi } from "../../../functionLib/hooks";
import { useConfig } from "../../../api/config";
import ButtonComponent from "../../UIComponents/ButtonComponent/ButtonComponent";
import TitledInput from "../../UIComponents/TitledInputTextComponent/TitledInputTextComponent";
import new_logo from "../../../assets/img/logo.png";
import "./ResetComponent.css";

const ResetComponent = () => {
  const api = useApi();
  const config = useConfig();
  const [redirect, setRedirect] = useState(false);
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState({
    data: "",
    name: "",
    borderColor: "",
  });

  const onSetShow = () => {
    setMessage({
      ...message,
      name: "",
    });
  };

  const createMessage = (messageText, name) => {
    setMessage({
      ...message,
      data: messageText,
      name,
    });
  };

  const onDisable = () => {
    if (!email) {
      createMessage("Please fill out this field", "email");
    }
  };

  const onReset = async () => {
    try {
      const checkEmail = await api.authorize.validateEmail({
        email: email,
        resetPassword: true,
      });

      if (checkEmail.existsCustomer) {
        const resetPassResult = await api.authorize.resetPassword(email);

        if (resetPassResult.status === "success") {
          window.notification.info(
            `We've just sent an email to ${email}, please check your mailbox and follow the instructions to reset your password!`,
            5
          );
          setTimeout(() => {
            setRedirect(true);
          }, 5000);
        } else {
          window.notification.error(resetPassResult.message, 5);
        }
      } else throw Error("No users found");
    } catch (error) {
      window.notification.error(error.message, 5);
      console.log(error);
    }
  };

  return redirect ? (
    <Redirect to="/login" />
  ) : (
    <div className="LoginComponent_main">
      <header>
        <img
          src={
            config.BRAND_IMAGE_LINK === "activechat"
              ? new_logo
              : config.BRAND_IMAGE_BIG_LINK || config.BRAND_IMAGE_LINK
          }
          alt=""
          onClick={() => {
            window.location = "/";
          }}
        />
      </header>

      <form className="CreateAccount_container">
        <div className="CreateAccount_main">
          <h2 className="CreateAccount_title_header">Reset your password</h2>
          <div className="CreateAccount_title_signup">
            Need another account? <Link to="/signup">Sign up!</Link>
          </div>
          <div className="emailInputContainer">
            {/* <InputPopupComponent
              value={email}
              onChange={e => {
                setEmail(e.target.value);
                createMessage("", "");
              }}
              placeholder='example@email.com'
              notification={{ ...message }}
              name='email'
              setShow={onSetShow}
              type='email'
            /> */}
            <TitledInput
              title="Email"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
                createMessage("", "");
              }}
              placeholder="example@email.com"
              name="email"
            />
          </div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <ButtonComponent
              className="login"
              onClick={onReset}
              locked={!email}
              onDisableClick={onDisable}
              acuikit
            >
              Send reset email
            </ButtonComponent>
          </div>
        </div>
      </form>
    </div>
  );
};

export default ResetComponent;
