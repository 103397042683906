import moment from "moment";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useApi } from "../../../../functionLib/hooks";
import svgLib from "../../../../functionLib/svglib";
import ButtonComponent from "../../../UIComponents/ButtonComponent/ButtonComponent";
import Select from "../../../UIComponents/Select/Select";
import Waiting from "../../../UIComponents/WaitingComponent/Waiting";
import { mainLangList, secondaryLangList } from "./botsLanguageArrays";
import "./BuildYourFirstBot.css";

interface BuildFirstBotProps {
  goToNextStep?: (number) => void;
  setBotId?: (string) => void;
  setBotName?: (string) => void;
}

const BuildYourFirstBot = ({
  goToNextStep = () => {},
  setBotId = () => {},
  setBotName = () => {},
}: BuildFirstBotProps): JSX.Element => {
  const api = useApi();
  const [step, setStep] = useState("lang");
  const [lang, setLang] = useState("en");

  const createNewAgentFromScratch = async () => {
    setStep("wait");
    try {
      const trial_end = moment().add(14, "days").unix() * 1000;
      const { data } = await api.agents.create({
        advancedSettings: {
          bot_language: lang,
        },
        trial_end,
        title: "My new bot",
        image:
          "https://storage.googleapis.com/activechat-200215.appspot.com/AC_symbol.png",
      });
      setBotId(data._id);
      setBotName(data.title);
      // setStep('goTo');
    } catch (error) {
      setStep("lang");
      window.notification.error(error.message, 5);
    }
  };
  return (
    <div className="signup_quiz_component">
      {step === "lang" && (
        <div className="build_your_first_bot_wrapper">
          <div className="signup_quiz_sequence_selector_question_wrapper">
            <span className="build_your_first_bot_step_text">
              Choose your virtual agent language
            </span>
          </div>
          <div className="build_your_first_bot_lang">
            <Select
              title="Language"
              acuikit
              onChange={(value) => setLang(value)}
              value={lang || "en"}
              options={[...mainLangList, ...secondaryLangList].map((item) => ({
                content: (
                  <div className="group_bot_select_option">{item.native}</div>
                ),
                value: item.code,
                name: item.native,
              }))}
            />
          </div>
          <div className="new_bot_lang_desc">
            {mainLangList.some((item) => item.code === lang) ? (
              <span>
                This language has full AI / NLP support with Intents, Insights
                and AI hints
              </span>
            ) : (
              <span>
                This language is partially supported. Natural language
                understanding (Intents) will work, but Insights and AI hints
                will not be available
              </span>
            )}
          </div>
          <ButtonComponent
            className="build_your_first_bot_button"
            acuikit
            onClick={() => createNewAgentFromScratch()}
          >
            Next
          </ButtonComponent>
        </div>
      )}

      {step === "wait" && (
        <div className="build_your_first_bot_wrapper">
          <div className="build_your_first_bot_wait">
            <Waiting text="Working..." />
          </div>
        </div>
      )}
      {step === "goTo" && (
        <div className="build_your_first_bot_wrapper">
          <div className="signup_quiz_sequence_selector_question_wrapper">
            <span className="build_your_first_bot_step_text">
              Your agent is almost ready!
            </span>
          </div>
          <div className="build_your_first_bot_goTo">
            <div className="build_your_first_bot_goTo_item">
              <div className="build_your_first_bot_goTo_item_header">
                {svgLib.go_to_intents("")}
              </div>
              <div className="build_your_first_bot_goTo_item_desc">
                <p>
                  Now let's teach it your specific knowledge. On the next screen
                  you can add links and documents that describe what your
                  company or product does, and we'll train the AI to respond to
                  questions on that data.
                </p>
              </div>
              <div className="build_your_first_bot_goTo_item_button">
                <ButtonComponent
                  className="build_your_first_bot_button"
                  acuikit
                  onClick={() => goToNextStep((prev) => prev + 1)}
                >
                  Upload the knowledge
                </ButtonComponent>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default BuildYourFirstBot;
