// import React from "react";
// import anim from "./img/anim.gif";
// import { useConfig } from "../../../api/config";
// import logo from "./img/logo.svg";
import "./Loading.css";

const LoadingComponent = ({ className = "" }) => {
  return <div className="LoadingComponent_wrapper"></div>;
};

export default LoadingComponent;
