import React, { useEffect, useState } from "react";
import LiveChat from "react-livechat";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import { useApi } from "../../../../functionLib/hooks";
import svglib from "../../../../functionLib/svglib";
import { useNotificationsActions } from "../../../../hooks/notifications/actions";
import { customerActions } from "../../../../redux/actions";
import { useAppBot } from "../../../../redux/bot/bot.selectors";
import { useAppCustomer } from "../../../../redux/customer/customer.selectors";
import { useAppDispatch } from "../../../../redux/hooks";
import { CustomerType } from "../../../../types/customer";
import SidebarComponent from "../../../UIComponents/SidebarComponent/SidebarComponent";
import AccountComponet from "../AccountComponent";
import AppNavBarComponentMain from "../AppNavBarComponent/AppNavBarComponent";
import BotsComponent from "../BotsComponent/BotsComponent";
import MarketComponent from "../MarketComponent/MarketComponent";
import NewBotComponent from "../NewBotComponent/NewBotComponent_v2";
import SelectedBotComponent from "../SelectedBotComponent/SelectedBotComponent";
import "./AppComponent.css";

const AppComponent = () => {
  const history = useHistory();
  const api = useApi();
  const { botAgent: selectedBot } = useAppBot();
  const { customer } = useAppCustomer();
  const appDispatch = useAppDispatch();
  const [data, setData] = useState<{ name: string; value: string }[]>([]);
  const [showSidebar, setShowSidebar] = useState(true);
  const [screenWidth, setScreenWidth] = useState(1);
  const [botsAmount, setBotsAmount] = useState(0);
  const n11s = useNotificationsActions();

  const handleResize = () => {
    if (window.innerWidth > 768) setScreenWidth(window.innerWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (screenWidth > 768) setShowSidebar(true);
  }, [screenWidth]);

  const getBotsList = () => {
    const settings = { json: false, skip: 0 };
    const fields = { _id: true };
    return api.agents
      .get(settings, fields)
      .then((agentsData) => setBotsAmount(agentsData.totalAmount))
      .catch((error) => {
        n11s.error(error.message, 5);
        console.error(
          "Error while taking bots list for the customer: ",
          error.message
        );
      });
  };

  useEffect(() => {
    const userId = document.cookie
      .split(";")
      .filter((element) => element.includes("userID"))
      .join("")
      .split("=");
    const lastBot = document.cookie
      .split(";")
      .filter((element) => element.includes("lastBot"))
      .join("")
      .split("=");

    if (customer && Object.keys(customer).length) {
      if (userId.length <= 1) {
        document.cookie = "userID=" + customer._id + "; path=/";
      }

      if (selectedBot && Object.keys(selectedBot).length) {
        document.cookie = "lastBot=" + selectedBot._id + "; path=/";
      }

      if (
        selectedBot &&
        !lastBot.filter((element) => element.includes(selectedBot._id))
          .length &&
        userId.filter((element) => element.includes(String(customer._id)))
          .length &&
        selectedBot &&
        Object.keys(selectedBot).length &&
        botsAmount &&
        (window as any).trackAttributes &&
        document.getElementById("ACCW_EMBED")
      ) {
        if (window.analytics && window.analytics.track) {
          (window as any).trackAttributes({
            _customer_id: customer._id,
            bot_ID: selectedBot._id,
            bot_plan: selectedBot.plan,
            bot_users: selectedBot.activeUsersCount,
            total_bots: botsAmount,
            _email: customer.cred.email,
            _first_name: customer.profile.given_name,
            _last_name: customer.profile.family_name,
          });
        }
      }
    }
  }, [selectedBot, botsAmount, customer]);

  useEffect(() => {
    if (
      selectedBot &&
      Object.keys(selectedBot).length &&
      (window as any).TooltipUpdateUserData
    ) {
      (window as any).TooltipUpdateUserData({
        botID: selectedBot._id,
        botPlan: selectedBot.plan,
      });
    }
  }, [selectedBot]);

  const fetchCustomer = async (customer: CustomerType) => {
    try {
      let havePro = false;

      if (customer._id) {
        havePro = await api.customers.checkCustomerProBots(customer._id);
      }

      const difference = new Date().getTime() - customer.createdAt;

      const days = Math.floor(difference / 1000 / 60 / 60 / 24);

      if (days < 14 || havePro) {
        const plan = days < 14 ? "Trial" : "Pro";
        const customerData = [
          {
            name: "user_id",
            value: `https://fly.customer.io/env/81761/people/${customer._id}`,
          },
          { name: "plan", value: plan },
        ];
        setData(customerData);
      } else {
        console.log("Customer is on a Free plan, LiveChat will not be enabled");
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (customer && customer._id) {
      fetchCustomer(customer);
    }
    if (
      customer &&
      Object.keys(customer).length &&
      !window.localStorage.getItem("tooltip")
    ) {
      const difference = new Date().getTime() - customer.createdAt;

      const days = Math.floor(difference / 1000 / 60 / 60 / 24);
      if ((window as any).initTooltip)
        (window as any).initTooltip({
          userID: customer._id,
          firstName: customer.profile.given_name,
          lastName: customer.profile.family_name,
          isTrial: days < 14,
        });
    }
  }, [customer]);

  const getCustomer = async () => {
    try {
      const customer1 = await api.customers.get();
      appDispatch(customerActions.setCustomer(customer1));
    } catch (error) {
      console.error("Error while taking customer: ", error.message);
      n11s.error(error.message, 5);
    }
  };

  useEffect(() => {
    getCustomer();
    history.listen(() => {
      n11s.close();
    });
    getBotsList();
  }, []);

  return (
    <div className="app_main_component_container">
      {showSidebar && (
        <div
          className="app_navbar_main_container"
          style={{
            height: "100%",
            width: "80px",
            position: window.innerWidth > 768 ? "relative" : "absolute",
          }}
        >
          <AppNavBarComponentMain />
          <div
            className="hide_navbar_right"
            onClick={() => setShowSidebar(false)}
          >
            {svglib.cross("#ffffff", { width: "20px", height: "20px" })}
          </div>
        </div>
      )}
      <div className="app_main_component_content">
        <Switch>
          <Route exact path="/" render={() => <Redirect to="/bots" />} />
          <Route
            exact
            path="/bots"
            render={(props) => (
              <BotsComponent {...props} setShowSidebar={setShowSidebar} />
            )}
          />
          <Route
            path="/bots/bot/:agentID"
            render={(props) => (
              <SelectedBotComponent
                {...props}
                setShowSidebar={setShowSidebar}
                showSidebar={showSidebar}
              />
            )}
          />
          <Route path="/market" component={MarketComponent} />
          <Route
            path="/account"
            render={(props) => (
              <AccountComponet
                {...props}
                setShowSidebar={setShowSidebar}
                showSidebar={showSidebar}
              />
            )}
          />
          <Route
            path="/bots/newbot/:property"
            render={(props) => <NewBotComponent />}
          />
        </Switch>
      </div>
      <SidebarComponent />
      <div className="lv_styles" style={{ margin: "0", padding: "0" }}>
        {customer && data.length > 0 && (
          <LiveChat
            license={11888595}
            visitor={{
              name: customer.profile.given_name,
              email: customer.cred.email,
            }}
            params={data}
          />
        )}
      </div>
    </div>
  );
};

export default AppComponent;
