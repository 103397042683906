import React from "react";
import { Link } from "react-router-dom";
import svgLib from "../../../../functionLib/svglib";

export type Step2Props = { newBotId: string };
export const Step2: React.FC<Step2Props> = ({ newBotId }) => (
  <div className="new_bot_body_step_2">
    <div className="build_your_first_bot_goTo">
      {
        // Item 1
      }
      <div style={{ width: 500 }} className="build_your_first_bot_goTo_item">
        <div className="build_your_first_bot_goTo_item_header">
          {svgLib.go_to_intents("")}
        </div>
        <div className="build_your_first_bot_goTo_item_desc">
          <p style={{ textAlign: "start" }}>
            {/* On the next step, you can use URL links (for example, a link to your
            F.A.Q. or a website) or text documents (DOC or PDF) to add knowledge
            to your project. Think of any textual data that describes your
            business, or addresses the questions that your customers or
            employees may ask. */}
          </p>
        </div>
        <div className="build_your_first_bot_goTo_item_button">
          <Link
            className="build_your_first_bot_button_wrapper"
            to={`/bots/bot/${newBotId}/automation/skills`}
          >
            <div>
              <span style={{ fontSize: 12 }}>Test your chatbot</span>
            </div>
          </Link>
        </div>
        {/* <a
          className="new_bot_header_desc"
          style={{
            textDecoration: "none",
            margin: 0,
            transform: "translateX(23%)",
          }}
          href="https://manual.activechat.ai/help-guides/managing-knowledge"
          target="_blank"
          rel="noopener noreferrer"
        >
          Check our knowledge management guide here
        </a> */}
      </div>
    </div>
  </div>
);
