import React, { useState } from 'react';
import svgLib from '../../../../functionLib/svglib';
import BuildYourFirstBot from '../BuildYourFirstBot/BuildYourFirstBot';
import SignUpQuizComponent from '../SignUpQuizComponent/SignUpQuizComponent';
import SignUpQuizV2Component from '../SignUpQuizComponent/SignUpV2Component/SignUpQuizV2Component';
import TrainTheAi from '../TrainTheAi/TrainTheAi';
import './WizzardComponent.css';

const WizzardComponent = () => {
    const [currentStep, setCurrentStep] = useState(0);
    const [customerID, setCustomerID] = useState('');
    const [botId, setBotId] = useState('');
    const [botName, setBotName] = useState('');

    const goToNextStep = () => {
        if (currentStep < wizzardStepsData.length - 1) {
            setCurrentStep(currentStep + 1);
        }
    };

    const goToPreviousStep = () => {
        if (currentStep >= 1) setCurrentStep(currentStep - 1);
    };

    // Determined the content and sequence of the wizard. Array of objects
    // [{
    //  title: 'Some title',
    //  component: SomeComponent
    // }]

    const wizzardStepsData = [
        {
            title: 'Create account',
            component: <SignUpQuizV2Component goToNextStep={goToNextStep} setCustomerID={setCustomerID} />,
        },
        {
            title: 'Choose your role',
            component: <SignUpQuizComponent goToNextStep={goToNextStep} customerID={customerID} />,
        },
        {
            title: 'Build your agent',
            component: <BuildYourFirstBot goToNextStep={goToNextStep} setBotId={setBotId} setBotName={setBotName} />,
        },
        {
            title: 'Train the AI',
            component: <TrainTheAi botId={botId} customerId={customerID} botName={botName} />,
        },
    ];

    return (
        <div className="wizzard_component_wrapper">
            <div className="wizzard_component_first_step_title">
                <span>Unlock the next level of customer service</span>
            </div>
            <WizzardHeader wizzardStepsData={wizzardStepsData} currentStep={currentStep} />
            <WizzardContent content={wizzardStepsData[currentStep].component} />
        </div>
    );
};

// Component to render all steps as points, current step, step name

const WizzardHeader = ({ wizzardStepsData = [], currentStep = 0 }) => (
    <div className="wizzard_header_wrapper">
        {wizzardStepsData.map((item, index) => (
            <WizzardHeadersStep
                key={index}
                stepIndex={index}
                stepTitle={item.title}
                current={currentStep === index}
                stepIsDone={currentStep > index}
            />
        ))}
    </div>
);

// Component to render single steps as points, current step, step name
const WizzardHeadersStep = ({ stepIndex = 0, stepTitle = '', current = false, stepIsDone = false }) => (
    <div className="wizzard_headers_step_wrapper">
        <div className="wizzard_headers_step_count_section">
            <div
                className={`wizzard_headers_step_count_section_circle ${
                    current ? 'wizzard_headers_step_count_section_circle_current' : ''
                }`}
            >
                <span className="wizzard_headers_step_count_section_number">
                    {stepIsDone ? svgLib.checked() : stepIndex + 1}
                </span>
            </div>
        </div>
        <div className="wizzard_headers_step_title_section">
            <div className="wizzard_headers_step_title_section_title">
                <span>{stepTitle}</span>
            </div>
        </div>
        <div className="wizzard_headers_step_line" />
    </div>
);

// Component to render current step content
const WizzardContent = ({ content = <div /> }) => <div className="wizzard_content_wrapper">{content}</div>;
export default WizzardComponent;
