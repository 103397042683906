import { useEffect } from "react";
import { useLocation } from "react-router";
import { useAppBot } from "../../../../../redux/bot/bot.selectors";
import { useAppCustomer } from "../../../../../redux/customer/customer.selectors";
import { CustomerType } from "../../../../../types/customer";
import { CustomerPermission } from "../../../../../types/customerRole";
import DataNavigation from "../DataNavigation";
import { getPageVisitName } from "../utils";

export const useRoot = () => {
  const { botId: selectedBotId } = useAppBot();
  const { customer, customerRoles } = useAppCustomer();
  const location = useLocation();
  useEffect(() => {
    if (window.analytics && window.analytics.track === "function") {
      window.analytics.track("Bot Settings Page Visited", {
        Tab: getPageVisitName(location.pathname),
        Platform: "Architect",
      });
    }
  }, [location]);

  const permissions =
    customerRoles.find((role) => role.botId === selectedBotId)?.permissions ??
    [];
  let { root, subRoot } = getRoot(location.pathname, customer, permissions);
  return {
    root,
    subRoot,
    permissions,
  };
};

function getRoot(
  pathname: string,
  customer: CustomerType | null,
  permissions: CustomerPermission[]
) {
  if (!customer) {
    return { root: undefined, subRoot: undefined };
  }
  let root: string | undefined = pathname.split("/")[4];
  let subRoot: string | undefined = pathname.split("/")[5];
  if (subRoot) {
    const rootNavigation = DataNavigation(customer, permissions).find(
      (item) => item.linkTo === root
    );
    const subRootNagivation = rootNavigation?.subRoutes.find(
      (item) => item.linkTo === subRoot
    );
    return {
      root: rootNavigation?.name,
      subRoot: subRootNagivation?.name,
    };
  } else {
    const rootNavigation = DataNavigation(customer, permissions).find(
      (item) => item.linkTo === root
    );
    return { root: rootNavigation?.name };
  }
}
