import { useEffect, useState } from "react";
import { v4 as uniqueId } from "uuid";
import { useHistory } from "react-router";
import { useApi } from "../../../../functionLib/hooks";
import { checkURL } from "../../../../functionLib/hooks";
import ButtonComponent from "../../../UIComponents/ButtonComponent/ButtonComponent";
import Dropdown from "../../../UIComponents/Dropdown/Dropdown";
import TitledInputTextComponent from "../../../UIComponents/TitledInputTextComponent/TitledInputTextComponent";
import FilePresentIcon from "@mui/icons-material/FilePresent";
import LinkIcon from "@mui/icons-material/Link";
import AddLinkIcon from "@mui/icons-material/AddLink";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import Popover from "@mui/material/Popover";
import "./TrainTheAi.css";

interface OnTrainBotProps {
  botId: string;
  customerId: string;
  botName: string;
}

const TrainTheAi = ({
  botId,
  customerId,
  botName,
}: OnTrainBotProps): JSX.Element => {
  const [data, setData] = useState<any[]>([]);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [anchorElDelete, setAnchorElDelete] =
    useState<HTMLButtonElement | null>(null);
  const [anchorElComplete, setAnchorElComplete] =
    useState<HTMLButtonElement | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [url, setUrl] = useState("");
  const api = useApi();
  const history = useHistory();

  const handleClickURL = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClickDelete = (event) => {
    setAnchorElDelete(event.currentTarget);
  };
  const handleClickComplete = (event) => {
    setAnchorElComplete(event.currentTarget);
  };

  const handleClosePop = () => {
    setAnchorEl(null);
  };
  const handleCloseDelete = () => {
    setAnchorElDelete(null);
  };
  const handleCloseComplete = () => {
    setAnchorElComplete(null);
  };
  const handleClickSavePop = () => {
    if (!data.some((e) => e.dataLink === url)) {
      setIsLoading(true);
      api.users
        .createKnowledge({
          customerId,
          botId,
          dataLink: url,
          type: "link",
          name: url,
        })
        .then((e) => {
          setData((prev: any) => [...prev, e]);
        })
        .then(() => {
          setUrl("");
          handleClosePop();
          window.notification.info("You added link", 5);
        })
        .catch((e) => window.notification.warning(e.message, 5))
        .finally(() => setIsLoading(false));
    }
  };
  useEffect(() => {}, [data]);

  const buttons = [
    {
      content: (
        <div
          key={"sds1"}
          className="dropdown_button_file"
          onClick={() => document?.getElementById("fileid")?.click()}
        >
          <FilePresentIcon className="transform" />
          <input
            type="file"
            hidden
            id="fileid"
            accept="application/pdf,application/msword,
                        application/vnd.openxmlformats-officedocument.wordprocessingml.document"
            onChange={(e) => {
              const file = e?.target?.files !== null && e?.target?.files[0];
              if (file && !data.some((elem) => elem.name === file.name)) {
                setIsLoading(true);
                api
                  .uploadFileToGSKnowledge(botId, file)
                  .then((el) => {
                    api.users
                      .createKnowledge({
                        botId,
                        customerId,
                        dataLink: el.url,
                        type: "file",
                        name: file.name,
                      })
                      .then((knowledge) =>
                        setData((prev: any) => [...prev, knowledge])
                      )
                      .then(() =>
                        window.notification.info("You added file", 5)
                      );
                  })
                  .catch((e) => window.notification.error(e.message, 5))
                  .finally(() => setIsLoading(false));
              }
            }}
          />
          <span className="test_button_group_bot_select_option_title">
            File
          </span>
        </div>
      ),
      value: "File",
      name: "File",
      onClick: () => document?.getElementById("fileid")?.click(),
    },
    {
      content: (
        <div key={"sds2"} className="dropdown_button_file">
          <div className="dropdown_button" onClick={handleClickURL}>
            <AddLinkIcon className="transform" />
            <span className="test_button_group_bot_select_option_title">
              URL link
            </span>
          </div>
          <Popover
            id={uniqueId()}
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={handleClosePop}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
          >
            <div style={{ width: "300px", padding: 10, textAlign: "center" }}>
              <TitledInputTextComponent
                value={url}
                onChange={(e) => setUrl(e.target.value)}
                title="url"
                style={{ width: "270px" }}
              />
              <br />
              <ButtonComponent
                onClick={() => {
                  if (checkURL(url)) {
                    handleClickSavePop();
                  } else {
                    window.notification.warning("URL is not valid", 5);
                  }
                }}
                acuikit
                className="button"
                id="mi123"
              >
                Save
              </ButtonComponent>
            </div>
          </Popover>
        </div>
      ),
      value: "Link",
      name: "Link",
      onClick: (e) => handleClickURL(e),
    },
  ];

  const createNewAgentFromScratch = () => {
    api.users
      .loadKnowledgeForDialog(botId, undefined)
      .then((e) => {
        if (e.ok) {
          api.agents
            .save({
              _id: botId,
              "advancedSettings.model": {
                modelType: "GPT",
                modelStatus: "training",
                modelLastTrainedAt: Date.now(),
              },
            })
            .then(() => {
              if (window.analytics && window.analytics.track === "function") {
                window.analytics.track("Model Training Started", {
                  userId: customerId,
                  botName: botName,
                  modelType: "Metadialog",
                  botID: botId,
                  documentsNo: data.length,
                });
              }

              history.push(`/bots/bot/${botId}/automation/knowledge`);
            });
        } else {
          window.notification.warning("No data", 5);
          history.push(`/bots/bot/${botId}/automation/knowledge`);
        }
      })
      .catch((error) => window.notification.error(error.message, 5));
  };

  return (
    <div className="signup_quiz_component">
      <div className="build_your_first_bot_wrapper">
        <div
          style={{ marginBottom: 25 }}
          className="signup_quiz_sequence_selector_question_wrapper"
        >
          <span className="build_your_first_bot_step_text">
            Upload the knowledge
          </span>
        </div>
        <div className="build_your_first_bot_goTo_item_desc">
          Files and links that you upload will be used to train the AI model
          which will be able to respond to questions on that data instantly. Use
          your F.A.Qs, documents, internal knowledge bases, or even templates
          used by yous support team
        </div>

        {data &&
          data?.length > 0 &&
          data?.map((e) => {
            return (
              <div className="list_items" key={e._id}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    {e.type === "link" ? (
                      <LinkIcon color="disabled" />
                    ) : (
                      <FilePresentIcon color="disabled" />
                    )}
                    <a
                      style={{
                        textDecoration: "none",
                        color: "black",
                        transform: "translateX(10px)",
                      }}
                      href={e.dataLink}
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      {e.name.slice(0, 40)}
                    </a>
                  </div>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <span
                      style={{ color: "grey", transform: "translateX(-10px)" }}
                    >
                      {e.type === "link" ? "URL link" : "File"}
                    </span>
                    <div>
                      <DeleteOutlineIcon
                        style={{ cursor: "pointer" }}
                        onClick={handleClickDelete}
                        color="disabled"
                      />
                      <Popover
                        id={uniqueId()}
                        open={Boolean(anchorElDelete)}
                        anchorEl={anchorElDelete}
                        onClose={handleCloseDelete}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "left",
                        }}
                      >
                        <div
                          style={{
                            width: "300px",
                            padding: 15,
                            textAlign: "center",
                          }}
                        >
                          <span>
                            Are you sure you want to delete this data source?
                          </span>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-around",
                              marginTop: 20,
                            }}
                          >
                            <ButtonComponent
                              onClick={() => {
                                const knowledge = {
                                  botId: e.botId,
                                  name: e.name,
                                  dataLink: e.dataLink,
                                  type: e.type,
                                };
                                if (e.type === "link") {
                                  api.users
                                    .deleteKnowledge(knowledge)
                                    .then(() => {
                                      handleCloseDelete();
                                      setData((prev: any) =>
                                        prev.filter((el) => el._id !== e._id)
                                      );
                                    })
                                    .then(() =>
                                      window.notification.info("Deleted", 2)
                                    )
                                    .catch((e) =>
                                      window.notification.warning(e.message, 5)
                                    );
                                } else {
                                  api
                                    .removeFileFromGSKnowledge(e.botId, e.name)
                                    .then(() => {
                                      api.users
                                        .deleteKnowledge(knowledge)
                                        .then(() => {
                                          handleCloseDelete();
                                          setData((prev: any) =>
                                            prev.filter(
                                              (el) => el._id !== e._id
                                            )
                                          );
                                        });
                                    })
                                    .then(() =>
                                      window.notification.info("Deleted", 2)
                                    )
                                    .catch((e) =>
                                      window.notification.warning(e.message, 5)
                                    );
                                }
                              }}
                              red
                              acuikit
                              className="button"
                              id="mi123"
                            >
                              Delete
                            </ButtonComponent>
                            <ButtonComponent
                              onClick={handleCloseDelete}
                              acuikit
                              className="button"
                              id="mi123"
                            >
                              Cancel
                            </ButtonComponent>
                          </div>
                        </div>
                      </Popover>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-around",
            marginBottom: 25,
          }}
        >
          <Dropdown
            className={isLoading ? "is_loading" : ""}
            color={data.length > 0 ? true : false}
            arrowColor={data.length > 0 ? "#006cff" : ""}
            acuikit
            zIndex={3}
            name="Add data"
            options={buttons}
          />
          <div>
            <ButtonComponent
              acuikit
              outline={data.length > 0 ? false : true}
              style={data.length > 0 ? {} : { backgroundColor: "#E6F1FF" }}
              onClick={(e) => {
                if (data.length > 0) {
                  createNewAgentFromScratch();
                } else {
                  handleClickComplete(e);
                }
              }}
            >
              <span style={{ fontWeight: 600, fontSize: 15 }}>Complete</span>
            </ButtonComponent>
            <Popover
              id={uniqueId()}
              open={Boolean(anchorElComplete)}
              anchorEl={anchorElComplete}
              onClose={handleCloseComplete}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
            >
              <div style={{ width: "300px", padding: 10, textAlign: "center" }}>
                <span>
                  Are you sure you want to skip the knowledge submission stage?
                </span>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <ButtonComponent
                    className="build_your_first_bot_button"
                    acuikit
                    onClick={() => handleCloseComplete()}
                  >
                    <span style={{ fontWeight: 600, fontSize: 15 }}>
                      Add data now
                    </span>
                  </ButtonComponent>
                  <ButtonComponent
                    className="build_your_first_bot_button"
                    acuikit
                    outline
                    style={{ backgroundColor: "#E6F1FF" }}
                    onClick={() => {
                      handleCloseComplete();
                      createNewAgentFromScratch();
                    }}
                  >
                    <span style={{ fontWeight: 600, fontSize: 15 }}>
                      I will do it later
                    </span>
                  </ButtonComponent>
                </div>
              </div>
            </Popover>
          </div>
        </div>
        <div className="build_your_first_bot_goTo_item_desc">
          You will be able to add more data later
        </div>
      </div>
    </div>
  );
};

export default TrainTheAi;
